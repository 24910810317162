<template>
	<div class="cw-app-wrapper promotion">
		<!--components-->
		<div class="cw-app-module" :class="{ bt_100: !hasPrds() }">
			<MStepper />
		</div>
		<!--//components-->

		<!-- 상품 담기 완료 안내 -->
		<div v-if="this.$store.getters['prd/prdChk']" class="impact_area">
			<span class="add_txt">
				Tip
			</span>
			주문을 위해 담겨진 상품은 '상품검색' 화면 하단에서 확인할 수 있어요!
		</div>
		<v-dialog
			v-if="this.$store.getters['prd/prdChk']"
			v-model="this.$store.getters['prd/prdChk']"
			persistent
			content-class="position_top"
			@keydown.esc="cancel"
			scrollable
		>
			<v-card class="aalert">
				<v-card-title>
					상품 담기 완료!
				</v-card-title>
				<v-card-text>
					선택한 상품을 담았습니다. <br />
					주문할 상품을 모두 담으셨다면<br />
					할인정보를 입력해 주세요. <br />
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-spacer></v-spacer>
					<v-btn class="btn_sub " text @click="prdChkReset">
						상품 추가하기
					</v-btn>
					<v-btn class="btn_confirm " text @click="next()">
						할인정보 입력
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!--// 상품 담기 완료 안내 -->
		<!-- 제휴 결합 내역 확인 -->
		<v-dialog scrollable max-width="312" v-model="popupShow">
			<section class="u-confirm-wrap card">
				<article class="contents">
					<v-card-title class="split">
						<h2 class="head">제휴 결합 내역 확인</h2>
						<p class="msg">
							제휴 결합 내역을 최종 확인해 주시고<br />다음 단계로 이동해
							주세요.
						</p>
					</v-card-title>

					<v-card-text>
						<template
							v-for="(product, i) in products.filter(v =>
								funeralProducts.some(x => v.oid === x.oid && v.oid2 === x.oid2)
							)"
						>
							<div class="card-list" :key="i">
								<h3 class="tit">제휴결합&nbsp;{{ i + 1 }}</h3>
								<ul class="card-items">
									<li>
										<span class="tag">신규주문</span>
										<span class="cover">
											<img
												:src="`${product.prd.color_file_path}`"
												:alt="product.prd.leader_model_nm"
											/>
										</span>
										<p class="name">[코웨이] {{ product.prd.prod_nm }}</p>
									</li>
									<li
										v-for="(funeralProduct, i) in funeralProducts.filter(
											v => v.oid === product.oid && v.oid2 === product.oid2
										)"
										:key="i + `a`"
									>
										<span class="cover">
											<img
												:src="`${funeralProduct.prd.imagePath}`"
												:alt="funeralProduct.prd.prodNm"
											/>
										</span>
										<p class="name">
											{{ `[상조] 라이프 ${funeralProduct.prd.prodCd}` }}
										</p>
									</li>
								</ul>
							</div>
						</template>
						<div
							class="card-list"
							v-for="(funeralProduct, i) in funeralProducts.filter(
								(v, i) =>
									funeralProducts.findIndex(
										x =>
											x.preOrder.ORDER_NO === v.preOrder.ORDER_NO &&
											v.type === 'pre'
									) === i
							)"
							:key="`funeralProduct-${i}`"
						>
							<h3 class="tit">제휴결합&nbsp;{{ preOrderCombinedCnt() + i }}</h3>
							<ul class="card-items">
								<li>
									<span class="tag">기존계정</span>
									<span class="cover">
										<img
											:src="`/img/COWAY CI.jpg`"
											:alt="funeralProduct.prd.prodNm"
										/>
									</span>
									<p class="name">
										[코웨이]
										{{ funeralProduct.preOrder.GOODS_NM }}
									</p>
								</li>
								<template
									v-for="(funeralItem, i) in funeralProducts.filter(
										v => v.type === 'pre'
									)"
								>
									<li
										v-if="
											funeralItem.preOrder.ORDER_NO ===
												funeralProduct.preOrder.ORDER_NO
										"
										:key="`funeralItem-${i}`"
									>
										<span class="cover">
											<img
												:src="`${funeralItem.prd.imagePath}`"
												:alt="funeralItem.prd.prodNm"
											/>
										</span>
										<p class="name">
											{{ `[상조] 라이프 ${funeralItem.prd.prodCd}` }}
										</p>
									</li>
								</template>
							</ul>
						</div>
					</v-card-text>
				</article>
				<article class="bottom">
					<v-card-actions>
						<div class="col">
							<v-btn class="btn-cancel" @click="popupShow = false">취소</v-btn>
						</div>
						<div class="col">
							<v-btn class="btn-confirm" @click="goPayPageSale()">확인</v-btn>
						</div>
					</v-card-actions>
				</article>
			</section>
		</v-dialog>
		<!-- //제휴 결합 내역 확인 -->

		<!-- 상품검색 -->
		<div class="cw-app-section">
			<div class="u-app-title">상품검색</div>
			<v-form
				class="u-app-search"
				ref="prdSearchForm"
				@submit.prevent="clickSearch"
			>
				<div class="flex-input">
					<v-text-field
						dense
						outlined
						class="search"
						inputmode="text"
						prepend-inner-icon="mdi-magnify"
						placeholder="제품명/종류/모델명/상품코드"
						@click:prepend-inner="clickSearch"
						v-model.trim="searchStr"
						:rules="rules.searchStr"
						@click="bottomPannelClick(false)"
					>
					</v-text-field>
				</div>

				<div class="flex-button">
					<v-btn large @click="clickSearch">검색</v-btn>
				</div>
			</v-form>
			<div
				class="u-item-tips mb-20"
				style="background-color: #fff;"
				v-if="
					orderType !== 'C' &&
						!prdSearchshow &&
						funeralAccessableYn === 'Y' &&
						!isForeigner &&
						custType !== 'L'
				"
			>
				<p class="txt">
					<span>Tip</span
					><em
						>상조 상품을 주문 할 수 있어요. 검색창에 '상조'를 검색해 보세요.<br />
						<a @click="openPopCowayLifeSolutionGuide()"
							>[상조 주문 가이드 자세히 보기]</a
						></em
					>
				</p>
			</div>

			<!-- 상품 : 카운트 -->
			<div class="wrap_search_infor" v-if="prdSearchshow">
				<p class="count">
					<span>총</span>
					<strong>{{ searchPrdCount | comma }}</strong>
					<span>건</span>
				</p>
			</div>
			<!--// 상품 : 카운트 -->

			<!-- 상품 : 검색 리스트 -->
			<div class="warp_item_box" v-if="prdSearchshow">
				<ul
					class="wrap_item_list"
					v-if="
						(prdList && prdList.length > 0 && !loading) ||
							(webPrdList && webPrdList.length > 0 && !loading)
					"
				>
					<li>
						<div
							class="item_list"
							v-for="(funeralPrd, i) in webPrdList"
							:key="i"
						>
							<div class="pic_area">
								<img
									:src="`${funeralPrd.imagePath}`"
									:alt="funeralPrd.prodNm"
									@click="openPrdPicture(funeralPrd)"
								/>
							</div>
							<div class="txt_area" @click="goPrdDetail(funeralPrd)">
								<h3 class="tit">{{ funeralPrd.prodNm }}</h3>
								<p class="sub_tit">
									{{ funeralPrd.prodGb }}
								</p>
								<dl class="price_area">
									<dt>월 납입금</dt>
									<dd>
										{{ funeralPrd.price | comma }}
									</dd>
								</dl>
							</div>
						</div>
						<div
							class="u-item-tips mb-20"
							v-if="
								prdSearchshow && webPrdList && webPrdList.length > 0 && !loading
							"
						>
							<p class="txt">
								<span>Tip</span
								><em>코웨이 제품과 결합하면 할인을 받을 수 있어요.</em>
							</p>
							<div class="btn">
								<v-btn
									text
									class="underline"
									@click="openPopCowayLifeSolutionGuide(1)"
									>자세히 보기</v-btn
								>
							</div>
						</div>
					</li>
					<li v-if="prdList.length > 0">
						<div class="item_list" v-for="(prd, i) in prdList" :key="i">
							<div class="pic_area">
								<img
									:src="`${prd.color_file_path}`"
									:alt="prd.leader_model_nm"
									@click="openPrdPicture(prd)"
								/>
							</div>
							<div class="txt_area" @click="goPrdDetail(prd)">
								<h3 class="tit">{{ prd.leader_model_nm }}</h3>
								<p class="sub_tit">
									{{ prd.prod_nm }}, {{ prd.erp_cd }}
									<template v-if="prd.kind_val_txt"
										>, {{ prd.kind_val_txt }}
									</template>
								</p>
								<dl class="price_area">
									<dt>등록비/렌탈료</dt>
									<dd>
										{{ prd.reg_price | comma }}/{{ prd.month_amt | comma }}
									</dd>
								</dl>
								<dl class="price_area">
									<dt>일시불</dt>
									<dd>{{ prd.price | comma }}</dd>
								</dl>
							</div>
						</div>
					</li>
				</ul>

				<div
					class="pre-no-data"
					v-else-if="
						(prdList && prdList.length === 0 && loading) ||
							(webPrdList && webPrdList.length === 0 && loading)
					"
				>
					<img
						src="/img/img_no_data.png"
						alt="조회중입니다. 잠시만 기다려주세요."
					/>
					<p>조회중입니다.<br />잠시만 기다려주세요.</p>
				</div>

				<div class="pre-no-data" v-else>
					<img src="/img/img_no_data.png" alt="검색결과가 없습니다." />
					<p>검색결과가 없습니다.</p>
				</div>
			</div>
			<!--// 상품 : 검색 리스트 -->
		</div>
		<!--// 상품 담기 완료 안내 -->

		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn
						large
						class="u-btn-active"
						@click="next()"
						:disabled="!hasPrds()"
					>
						할인정보 입력하기
					</v-btn>
				</div>
			</div>
		</div>

		<!--// 상품검색 -->

		<!-- 팝업 컴포넌트 -->
		<!-- 장바구니, 미리보기 -->
		<PrdPreview
			ref="prdPreview"
			:bottomPannel="bottomPannel"
			@bottomPannel="bottomPannelClick"
		/>
		<!-- 침대 패키지 설정 -->
		<!--// 팝업 컴포넌트 -->
		<!--상품이미지 컴포넌트 -->
		<PrdPictureDialog ref="prdPicture" />
		<PopCowayLifeSolutionGuide ref="popCowayLifeSolutionGuide" />
	</div>
</template>

<script>
import {
	mOrderInfoByEtc,
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import { workerType } from '@/apis/order.schedule.api'
import { fetchPrds, fetchPrdOpts01 } from '@/apis/order.prd.api'
import filters from '@/mixins/filters'
import computeds from '@/mixins/computeds'
import { regex } from '@/utils/rules'
import { isProduction } from '@/utils'
import { registerCallbackToApp, closePopView } from '@/utils/navigation'
import { hasPrds, getMessageByStatusCode } from '@/utils/ec'
import PrdPreview from '@/views/prd/PrdPreview'
import { getPreContractSalesChk } from '@/apis/pre.contract.api'
import PopCowayLifeSolutionGuide from '@/views/common/PopCowayLifeSolutionGuide'
import { isParameterEmpty } from '@/utils/common'

const SALE_INFO = {
	prdAmt: [],
	saleAmt: []
}

export default {
	mixins: [filters, computeds],
	components: {
		PrdPreview,
		PopCowayLifeSolutionGuide
	},
	data() {
		return {
			dialog: [],
			bottomPannel: false, // 하단 장바구니 펼침, 접힘
			prdSearchshow: false, // 상품 검색결과 노출 여부
			searchStr: '', // 검색 내용
			prdList: [], // 상품 리스트 (상품검색용)
			webPrdList: [], // 모주 웹 DB 상품 리스트 (상품검색용)
			products: [], // 상품 리스트 (침대패키지설정, 할인정보)
			funeralProducts: [], //상조 상품 리스트
			searchPrdCount: 0,
			rules: {
				searchStr: [v => this.rulesSearchStr(v)]
			},
			filterList: [],
			workerTypeList: [],
			previewSize: 0,
			isProduction: isProduction(),
			orderType: '', // 주문구분값 (사전청약(S), 일반주문(N)
			loading: false,
			type: this.$store.getters['common/type'],
			custType: this.$store.getters['verify/customerType'],
			isForeigner: false, //고객 구분(외국인),
			popupShow: false,
			funeralAccessableYn: this.$store.getters['common/funeralAccessableYn']
		}
	},
	created() {
		this.initAppPage()
		const customer = this.$store.getters['verify/customer']
		this.isForeigner = customer?.custPassInfo.fgnGbn !== '1'

		// 일반주문, 청약 주문 구분값 저장
		if (this.$store.state['verify'].customer.orderType !== '') {
			this.orderType = this.$store.state['verify'].customer.orderType
		} else {
			this.orderType = 'N'
		}
	},
	methods: {
		hasPrds,
		getMessageByStatusCode,
		isParameterEmpty,
		// 상품 장바구니 패널
		bottomPannelClick(param) {
			this.bottomPannel = param
		},

		// 상품 이미지 상세보기
		openPrdPicture(prd) {
			this.$refs.prdPicture.open(prd)
		},
		// 정렬
		changeSort(code) {
			switch (code) {
				case 'NEW':
					this.prdList.sort((a, b) => {
						if (a.come_out_dt < b.come_out_dt) return 1
						if (a.come_out_dt > b.come_out_dt) return -1
						return 0
					})
					break
				case 'LOWRENT':
					this.prdList.sort((a, b) => {
						if (a.month_amt > b.month_amt) return 1
						if (a.month_amt < b.month_amt) return -1
						return 0
					})
					break
				case 'HIGHRENT':
					this.prdList.sort((a, b) => {
						if (a.month_amt < b.month_amt) return 1
						if (a.month_amt > b.month_amt) return -1
						return 0
					})
					break
				case 'LOWDEBIT':
					this.prdList.sort((a, b) => {
						if (a.price > b.price) return 1
						if (a.price < b.price) return -1
						return 0
					})
					break
				case 'HIGHDEBIT':
					this.prdList.sort((a, b) => {
						if (a.price < b.price) return 1
						if (a.price > b.price) return -1
						return 0
					})
					break
				case 'WEB':
					this.webPrdList.sort((a, b) => {
						if (a.price < b.price) return 1
						if (a.price > b.price) return -1
						return 0
					})
					break
			}
		},
		// 상품 검색
		clickSearch() {
			this.loading = true
			this.$refs.prdPreview.bottomPannel = false
			if (!this.$refs.prdSearchForm.validate()) {
				return
			}
			if (!this.prdSearchshow) {
				this.prdSearchshow = !this.prdSearchshow
			}

			fetchPrds({
				search_string: this.searchStr,
				codyNo: this.$store.getters['common/codyNo'],
				customerNo: this.$store.getters['verify/custNo'],
				cate1: this.funeralAccessableYn, //상조 접근 가능 여부
				cate2: this.orderType || '' // 주문 유형
			})
				.then(res => {
					if (res.resultObject.result) {
						this.prdList = res.resultObject.result
						this.searchPrdCount = this.prdList.length
						this.webPrdList = res.resultObject.webItemList
						this.searchPrdCount += this.webPrdList.length
					} else {
						this.prdList = []
						this.webPrdList = []
						this.searchPrdCount = 0
					}
					if (this.prdList.length > 0) this.changeSort('NEW')
					if (this.webPrdList.length > 0) this.changeSort('WEB')
					this.prdList.forEach(() => {
						this.dialog.push(false)
					})

					this.loading = false
				})
				.finally({})
		},

		// 검색 입력값 검증 및 안내 메세지
		rulesSearchStr(v) {
			const re = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\\*|\\-]+$/
			return regex(v, re, '검색어를 한글, 영문, 숫자, -만 입력해 주세요.')
		},

		// 상품선택 상세
		async goPrdDetail(prd) {
			let params = {
				prd: prd
			}
			if (prd?.webProd === 'Y') {
				if (this.isForeigner) {
					await this.$alert({
						message: '외국인 고객은 상조상품 구매가 불가합니다.',
						okText: '확인'
					})
				} else if (this.custType === 'L') {
					await this.$alert({
						message: '개인사업자 고객은 상조상품 구매가 불가합니다.',
						okText: '확인'
					})
				} else {
					this.$router.push({
						name: 'funeral-prd-detail',
						params: { ...params }
					})
				}
			} else {
				// 사전예약으로 담은 상품의 갯수, 가능 갯수 체크
				if (this.orderType === 'S') {
					const prdList = this.$store.getters['prd/prdList']
					if (prdList.length > 1) {
						const preOrderItems =
							prdList[prdList.length - 1].options.preOrderItems
						let preOrderSubCount = 0
						preOrderItems.forEach(v => {
							if (v.RESULT === 'T' && v.PRESUB !== '' && v.PRESUB === 'M') {
								preOrderSubCount += 1
							}
						})

						this.$log('preOrderSubCount', preOrderSubCount)
						this.$log('prdList.length', prdList.length)

						if (prdList.length >= preOrderSubCount) {
							this.$alert({
								message: `사전예약으로 주문 가능한 건이 ${preOrderSubCount}건 이므로
            더이상 상품을 선택할 수 없습니다.`
							})
							return
						}
					}
				}

				let presubCd = this.orderType === 'S' ? 'X' : ''
				presubCd = this.orderType === 'C' ? 'C' : presubCd
				// 상품정보 및 판매구분 호출
				if (this.orderType === 'S') {
					// 사전예약의 경우 상품 검색에서 조회
					let res = await fetchPrdOpts01({
						matnr: prd.erp_cd,
						kunnr: this.$store.state['verify'].customer.custNo, // 고객번호
						salesCd: this.$store.getters['common/codyNo'], // 코디번호 코디사번
						presub: presubCd // 사전청약(S): X, 계약이면(C): C, 그외 빈값
					})

					const IT_ZSDS1034 = res.resultObject.item.IT_ZSDS1034
					if (IT_ZSDS1034.length > 0 && IT_ZSDS1034[0].PRDHA) {
						params = Object.assign(params, {
							prdOpts01: res
						})
					}
				}

				this.$router.push({
					name: 'prd-detail',
					params: { ...params }
				})
			}
		},

		// 웹 주문정보 조회 및 저장
		async webOrderInfoSave() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			let result = null

			// 웹 주문정보 조회
			await fetchWebOrderInfoById(mobOrderNo).then(async res => {
				let webParams = res.resultObject
				let order = JSON.parse(webParams.orderInfoJson)

				order = Object.assign(order, {
					prdList: this.$store.getters['prd/prdList'],
					funeralPrdList: this.$store.getters['prd/funeralPrdList'],
					products: this.products,
					funeralProducts: this.funeralProducts,
					deliveryInfos: this.$store.getters['schedule/deliveryInfos']
				})

				webParams.step = '03' // 할인선택
				webParams.statusCode = '104' // 할인선택
				webParams.orderInfoJson = JSON.stringify(order)

				// 웹 주문 저장
				result = await postWebOrderInfoById(mobOrderNo, webParams)
			})
			return result
		},

		// 상품 수량만큼 주문생성
		async payProducts() {
			const products = this.$store.getters['prd/prdList']
			const payProducts = []
			const deliveryInfos = []
			await products.forEach(item => {
				let i = 1
				while (i <= item.options.quantity) {
					payProducts.push({
						prd: item.prd,
						options: item.options,
						payOptions: {
							quantity: 1,
							orderType: undefined,
							returnPromotionApplied: !!item.options.returnPromotionApplied,
							returnPenaltyApplied: !!item.options.returnPenaltyApplied,
							returnPenaltyAmt: item.options.returnPenaltyAmt ?? 0,
							priceAddTypes: [],
							priceDcTypes: []
						},
						esProd: { ...item.esProd },
						valueOptions: {
							min: 0,
							minEvent: 'CustomMinEvent',
							max: item.esProd.PRICE,
							maxEvent: 'CustomMaxEvent'
						},
						oid: item.oid || crypto.randomUUID(),
						oid2: crypto.randomUUID(),
						saleInfo: SALE_INFO
					})
					++i

					// 배정판 정보 셋팅
					// workerType 작업담당조직 (SERVICE_MANAGER, DELEGATE_3PL, AIR_3PL, EXPRESS)
					let workerType = ''
					this.workerTypeList.data.filter(value => {
						if (value.prodHCode === item.esProd.PRODH.substr(0, 6)) {
							workerType = value.workerType
						}
					})
					if (
						item.prd.regexpressyn === 'Y' ||
						item.prd.rentalexpressyn === 'Y'
					) {
						workerType = 'EXPRESS'
					}

					let prdOptionItemCdList = []
					prdOptionItemCdList.push(item.options.freeStand?.code)
					prdOptionItemCdList.push(item.options.hangerType?.code)
					prdOptionItemCdList.push(item.options.hangerType2?.code)
					prdOptionItemCdList.push(item.options.filterType?.code)
					prdOptionItemCdList.push(item.options.filterType2?.code)
					prdOptionItemCdList.push(item.options.filterType3?.code)
					prdOptionItemCdList.push(item.options.filterType4?.code)
					prdOptionItemCdList.push(item.options.prdOption2?.code)
					if (item.options.ET_FREE_ADD) {
						item.options.ET_FREE_ADD.filter(v => {
							prdOptionItemCdList.push(v.MATNR)
						})
					}
					prdOptionItemCdList = prdOptionItemCdList.filter(v => {
						return v !== undefined && v !== null && v !== ''
					})

					let deliveryInfo = {
						workerType: workerType,
						goodsCd: item.esProd.GOODS_CD,
						prodNm: item.esProd.GOODS_NM,
						leaderModelNm: item.prd.leader_model_nm,
						prdOptionItemCdListStr: prdOptionItemCdList.join('@'),
						preOwnerYm: item.options.preOwnerYm,
						prodh: item.esProd.PRODH.substr(0, 6),
						paymentType:
							item.esProd.CONTRACT_ID === '1'
								? item.esProd.ONCE_GRADE2
								: item.esProd.USE_TP,
						promotionCode: '',
						electricRangeType: item.options.setupType
							? item.options.setupType.code
							: item.options.freeStand
							? 'F'
							: ''
					}
					deliveryInfos.push(deliveryInfo)
				}
			})
			this.products = payProducts
			this.$store.dispatch('pay/savePayPrd', payProducts)

			this.$log('deliveryInfos: ', deliveryInfos)
			this.$store.dispatch('schedule/setDeliveryInfos', deliveryInfos)
		},
		// 상품 수량만큼 주문생성
		async funeralPayProducts() {
			const payProducts = this.$store.getters['pay/payProducts']
			const funeralProducts = this.$store.getters['prd/funeralPrdList']
			const funeralPayProducts = []
			await funeralProducts.forEach(item => {
				let i = 1
				const tmpPayProducts = payProducts.filter(v => v.oid === item.oid)
				while (i <= item.options.quantity) {
					funeralPayProducts.push({
						prd: item.prd,
						type: item.type,
						preOrder: item.preOrder,
						options: {
							quantity: 1,
							paymentMonth: item.options.paymentMonth
						},
						oid: item.oid || crypto.randomUUID(),
						oid2: tmpPayProducts[item.oidPrdIndex]?.oid2 || crypto.randomUUID(),
						oid3: crypto.randomUUID(),
						oidPrdIndex: item.oidPrdIndex,
						funeralPrdOid: item.funeralPrdOid,
						sellType: item.sellType
					})
					++i
				}
			})
			this.funeralProducts = funeralPayProducts
			this.$store.dispatch('pay/saveFuneralPayPrd', funeralPayProducts)
		},
		// 할인 선택으로 이동
		async next() {
			// 선택한 상품 목록
			const orderPrdList = this.$store.getters['prd/prdList'] || []
			const prdParam = { prodHList: [] }
			let goNext = true
			if (orderPrdList.length > 0) {
				for await (const prd of orderPrdList) {
					prdParam.prodHList.push(prd.esProd.PRODH.substr(0, 6))
				}
				const workerTypeList = await workerType(prdParam)
				this.workerTypeList = workerTypeList
				goNext = await this.chkGoNext(workerTypeList)
			}
			if (!goNext) {
				await this.$alert({
					message: '설치유형 구분이 확인되지 않은 상품이 있습니다.'
				})
				return
			}

			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return
			let arrParam = {
				id: mobOrderNo, // 웹주문번호
				statusCode: '104'
			}
			const res = await mOrderInfoByEtc(arrParam)
			let msg = undefined
			msg = getMessageByStatusCode(res.resultObject.statusCode)
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							dept_cd: this.$store.getters['common/deptCd'],
							type: this.$store.getters['common/type']
						}
					})
					.catch()
			} else {
				this.$store.dispatch('prd/setPrdChk', false)
				await this.preContractCheck()
			}
		},

		// 담당 조직 조회 타입 체크 (담당 조직 조회 API 오류발생 또는 workerType이 UNKNOWN인 경우 alert노출 및 진행불가)
		async chkGoNext(res) {
			let yn = true
			if (!(res && res.code === 'SUCCESS')) {
				yn = false
				this.$log('/booking/worker/type api error')
			} else if (res) {
				res.data.filter(item => {
					if (item.prodHCode === 'UNKNOWN') {
						yn = false
						this.$log(`prodHcode(${item.prodHCode}) is UNKNOWN`)
					}
				})
			}
			return yn
		},

		async preContractCheck() {
			await this.payProducts()
			await this.funeralPayProducts()

			// 사전계약 관리 판매인 체크
			const preContractSaleCheck = await this.preContractSaleChk()
			if (!preContractSaleCheck) return

			// 웹주문 저장
			const result = await this.webOrderInfoSave()
			// 할인 선택으로 이동
			if (result) {
				if (this.funeralProducts && this.funeralProducts.length > 0) {
					this.popupShow = true
				} else {
					this.$router.push({ name: 'order-pay' })
				}
			}
		},

		initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack',
					isNotUseDsHeaderPhysicalBack: true
				},
				this
			)
		},
		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '홈화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) closePopView()
		},

		// 상품 초기화
		prdChkReset() {
			this.bottomPannelClick(false)
			this.$store.dispatch('prd/setPrdChk', false)
		},
		// 사전 계약 관리판매인 변경 체크
		async preContractSaleChk() {
			if (this.orderType !== 'C') return true // 사전계약이 아니면 Pass

			const preOrderList = []
			const products = this.$store.getters['pay/payProducts']
			products.forEach(product => {
				if (product.options.preOrder !== undefined)
					preOrderList.push(product.options.preOrder.ORDER_NO)
			})

			if (preOrderList.length > 0) {
				return await getPreContractSalesChk({
					saleCd: this.$store.getters['common/codyNo'],
					orderNos: [...preOrderList],
					mobOrderNo: this.$store.getters['verify/mobOrderNo']
				})
					.then(async res => {
						const { result } = res.resultObject
						if (result !== 'T') {
							// 주문 삭제
							await this.$alert({
								message:
									'선택된 기존계정의 관리판매인이 변경된 것으로 확인되어 해당 주문이 삭제되었습니다. '
							})
							const mobOrderNo = this.$store.getters['verify/mobOrderNo']

							const res = await fetchWebOrderInfoById(mobOrderNo)
							const webParams = res.resultObject
							webParams.statusCode = '203'

							postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
								this.$log('postRes: ', JSON.stringify(postRes))
								// 모바일 주문현황으로 이동
								this.$router.push({ name: 'order-status' })
							})
							return false
						} else {
							return true
						}
					})
					.catch(err => {
						this.$log(err)
						this.$alert(
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
						)
					})
			}

			return true
		},
		goPayPageSale() {
			this.$router.push({ name: 'order-pay' })
		},
		openPopCowayLifeSolutionGuide(tabId) {
			this.$refs.popCowayLifeSolutionGuide.open(tabId)
		},
		preOrderCombinedCnt() {
			return (
				this.funeralProducts.filter(
					(v, i) =>
						this.funeralProducts.findIndex(
							x => x.oid === v.oid && x.oid2 === v.oid2 && v.type === 'new'
						) === i
				).length + 1
			)
		}
	}
}
</script>
